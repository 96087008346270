import React, {useState , useEffect} from 'react';


const Footer = ({showBelow}) => {
    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        }else {
            if (show) setShow(false)
        }
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    const handleClick = () => {
        window[`scrollTo`]({top: 0, behavior: `smooth`})
    }
    return (
        <footer className="rn-footer-area bg-color-primary">
            {/* <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <h3 className="title">Let’s work together on  <br /> your next project</h3>
                                <ul className="social-icons justify-content-center">
                                    <li><a href="https://www.linkedin.com/in/michael-vick-3a642a216/" target="_blank"><span>Linkedin</span></a></li>
                                    <li><a href="https://github.com/michaelvickdev" target="_blank"><span>Github</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="wrapper">
                <div className="copyright ptb--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                            <div className='row'>
                                <p className="copyright-text">© {new Date().getFullYear()}, Michael Vick</p>
                                <div className='ml-24'>
                                <ul className="social-icons">
                                            <li><a href="https://www.linkedin.com/in/michael-vick-3a642a216/" target="_blank"><span>Linkedin</span></a></li>
                                            <li><a href="https://github.com/michaelvickdev" target="_blank"><span>Github</span></a></li>
                                        </ul>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="back-to-top">
                                    <div className="backtop" onClick={handleClick}>
                                        <span className="top"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer
